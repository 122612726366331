<template>
    <div id="principal" class="mt-2">
        <div class="d-flex flex-column bg-white">
            <div class="row m-0" v-for="opcao in opcoesMenuRapido" :key="opcao.id">
                <div class="col-12 py-2">
                    <botao-opcao-home :titulo-botao="opcao.titulo" :subtitulo-botao="opcao.subTitulo" :icone="opcao.icone" :rota="opcao.rotaDestino" class="col-12"> </botao-opcao-home>
                </div>
            </div>
            <!-- <button @click="getInfo()">teste</button>
            <pre>Video DEVICE resultDevice:{{ resulVideoDevice }}</pre>
            <pre>DEBUG:{{ device }}</pre> -->
        </div>
        <!-- <botao-criar-atalho /> -->
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BotaoOpcaoHome from '@/components/botao-opcao-home.vue';
// import BotaoCriarAtalho from '@/components/botao-criar-atalho.vue';

export default {
    name: 'Home',

    components: {
        BotaoOpcaoHome
        // BotaoCriarAtalho
    },
    data() {
        return {
            opcoesMenuRapido: [
                {
                    id: 1,
                    titulo: 'Produtos',
                    subTitulo: 'Pesquisa de Saldos e Localização',
                    icone: 'fas fa-search',
                    rotaDestino: 'produto-pesquisa'
                },
                {
                    id: 2,
                    titulo: 'Painel de Movimentações',
                    subTitulo: 'Requisições e Operações de Entrada e Saída',
                    icone: 'fas fa-random',
                    rotaDestino: 'movimentacoes'
                },
                {
                    id: 3,
                    titulo: 'Balanço Fácil',
                    subTitulo: 'Conferência de Inventário',
                    icone: 'fas fa-file-alt',
                    rotaDestino: 'filtros-balancos'
                }
            ]
        };
    },
    computed: {
        ...mapGetters({
            isLoading: 'uiState/isLoading',
            device: 'uiState/device'
        })
    },
    mounted() {
    },
    methods: {}
};
</script>

<style scoped>
.home-container-app {
    margin: 0 auto;
    display: flex;
    height: 100vh;
    flex-direction: column;
}

.home-container-header {
    height: fit-content;
}

.home-container-body {
    flex-grow: 1;
    overflow: auto;
}
</style>
